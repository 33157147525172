import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RevolutCard from "../Image/Footer/revolut-card.png"
import { BiSolidOffer } from "react-icons/bi";
import { GiDeliveryDrone } from "react-icons/gi";
import { FaCreditCard } from "react-icons/fa6";
import { FcCustomerSupport } from "react-icons/fc";
import { FaExchangeAlt } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  Services_section: {
    backgroundColor: "#000",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontFamily: "'Asul', sans-serif",
  },
  Services_wrapper: {
    display: "flex",
    gap: "2.5rem",
    width: "100%",
    flexWrap: "wrap",
    height: "auto",
    paddingTop: "20px",
    justifyContent: "center",
  },
  Services_card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Ensures content is centered inside the card
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    margin: theme.spacing(0.5, 0.5), // Equal margin on top, bottom (2), and left, right (1)
    width: "calc(35% - 2rem)", // Ensures symmetrical width by accounting for margins
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adds subtle shadow for better appearance
    
    // Add responsive behavior for smaller screens
    [theme.breakpoints.down("sm")]: {
      width: "75%", // Full width on small screens (mobile devices)
      margin: theme.spacing(1, 0), // Vertical margin for better spacing
    },
  },
      Services_icon: {
    color: "#111",
    fontSize: "3rem",
    marginRight: theme.spacing(2.5),
    "& svg": {
      fontSize: "3rem !important",
    },

  },
  Services_cardTitle: {
    color: "#111",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  Services_cardInfo: {
    color: "black",
    fontWeight: 300,
    fontSize: "0.8rem",
  },
}));

const servicesData = [
  
  
  {
    id: 1,
    icon: <FaExchangeAlt fontSize="large" />,
    title: "14 Days Returns",
    info: "14 days money back guarantee",
  },
  {
    id: 2,
    icon: <GiDeliveryDrone fontSize="large" />,
    title: "Free Worldwide Shipping",
    info: "On all orders between 10-20 days",
  },
  // {
  //   id: 3,
  //   icon: <img style={{ width: 90, height: 'auto'}} src={RevolutCard} />,
  //   title: "Revolut Checkout",
  //   info: "Mastercard | Visa | American Express",
  // },
  {
    id: 4,
    icon: <FcCustomerSupport fontSize="large" />,
    title: "24/7 Support",
    info: "Our support team is always available.",
  },
  ];

const Services = () => {
  const classes = useStyles();

  return (
    <>
      <div  className={classes.Services_section}>
        <div className={classes.Services_wrapper} style={{ width: "100%" }}>
          {servicesData.map((item) => {
            return (
              <div className={classes.Services_card} key={item.id}>
                <div className={classes.Services_icon}>{item.icon}</div>
                <div>
                  <div className={classes.Services_cardTitle}>{item.title}</div>
                  <div className={classes.Services_cardInfo}>{item.info}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Services;
