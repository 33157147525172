import React, { useState } from "react";
import SearchBar from "./Searchbar";
import "./Header.css";

import CartIcon from "./CartIcon";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

import ProfileModal from "./ProfileModel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  contactUs: {
    width: '100%',
    backgroundColor: '#000',
    color: '#fff',
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    position: 'relative',
    zIndex: 999,
    overflow: 'hidden',
  },
  slidingText: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    paddingLeft: '40%', // Reduce padding to make text appear faster
    animation: '$slide 15s linear infinite',
    animationPlayState: 'running',
    margin: '6px',
    fontSize: "18px",
    opacity: "1 !important",
    cursor: 'pointer',
    color: '#fff !important',
  },
  '@keyframes slide': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
}));

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, user } = useSelector((state) => state.userData);
  const { cloaked } = useSelector((state) => state.products);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [country, setCountry] = useState("in"); // this is for flag
  const [sideMenu, setSideMenu] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  // this is for handle sideBar
  const handleSideBarMenu = () => {
    setSideMenu(!sideMenu); 
  };

  const handleTextClick = () => {
    setIsPaused(!isPaused); // Toggle between paused and running states
  };

  // this is for country selection
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  // this is for Search Button toggle
  const handleSearchButtonClick = () => {
    setSearchBarActive(!searchBarActive);
  };

  // this is for input value of Search bar.
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // this is for handle searching ...
  const handleSearchFormSubmit = (event) => {
    // window.fbq('trackCustom', 'SearchAttempt', {
    //   search_string: searchValue,
    // });    
    event.preventDefault();
    if (searchValue.trim()) {
      history.push(`/products/${searchValue}`);
    } else {
      history.push("/shop");
    }
  };

  // this is for sideBar Toggle Button
  const handleCrossButtonClick = () => {
    setSearchValue("");
    setSearchBarActive(!searchBarActive);
  };

  return (
    <>
      <div className="header">
        {/* <div className="headerTop">
          <div className="headerTopLeft">
            <p>We Offer's Free Shipping </p>
          </div>
          <div className="headerTopRight">
            <div className="headerRetailer">
              <span>
                <LocationOnIcon className="headerRetailer_Svg" />
              </span>
              <span>FIND LOCATION</span>
            </div>

            <div className="headerFlag">
              <span>
                <FlagSelect value={country} onChange={handleCountryChange} />
              </span>
            </div>
 
            <div className="headerLogin">
              {isAuthenticated ? (
                <Link
                  to="/account"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <button>My Account</button>
                </Link>
              ) : (
                <Link
                  to="/signup"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <button>Sign Up</button>
                </Link>
              )}
            </div>
          </div>
        </div>*/}

        {/* nav */}
        <div className="headerBottom">
          <div className="headerBottom__logo">
            <div className="header_mobile_menu">
              <span>
                {/* <ReorderIcon
                  onClick={() => setSideMenu(!sideMenu)}
                  sx={{
                    fontSize: 29,
                    color: "black",
                    "&:hover": {
                      color: "#e7070f",
                      cursor: "pointer",
                    }, 
                  }}
                /> */}
                {/* {sideMenu && (
                  <Sidebar
                    handleSideBarMenu={handleSideBarMenu}
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                )} */}
              </span>
              <span>
                <SearchBar
                  searchBarActive={searchBarActive}
                  searchValue={searchValue}
                  handleCrossButtonClick={handleCrossButtonClick}
                  handleSearchButtonClick={handleSearchButtonClick}
                  handleSearchInputChange={handleSearchInputChange}
                  handleSearchFormSubmit={handleSearchFormSubmit}
                />
              </span>
            </div>
          </div>
          {!searchBarActive && (
            <Link to="/">
              <img
                src={require("../../../Image/1.png")}
                alt="logo"
                className="headerBottom__logo_main"
              />
            </Link>
          )}

          {/* navmenu */}

          {!searchBarActive && (
            <div className="headerBottom_navMenu">
              <ul>
                <li>
                  <Link className="nav-link"  to="/">Home</Link>
                </li>

                <li>
                  <Link className="nav-link" to="/shop">Shop</Link>
                </li>
                {/* <li>
                  <Link className="nav-link" to="/contact">Контакт</Link>
                </li> */}
                <li>
                  <Link className="nav-link" to="/policy/privacy">About us</Link>
                </li>
              </ul>
            </div>
          )}

          {/* icons */}

          <div className="headerBotttom_icons">
            <div className="search_Bar">
              <SearchBar
                searchBarActive={searchBarActive}
                searchValue={searchValue}
                handleCrossButtonClick={handleCrossButtonClick}
                handleSearchButtonClick={handleSearchButtonClick}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchFormSubmit={handleSearchFormSubmit}
              />
            </div>
            <span>
              <Link
                to="/cart"
                style={{ textDecoration: "none" }}
              >
                <CartIcon className="cartIcon" size={30} />
              </Link>
            </span>
            <span>
              <ProfileModal user={user} isAuthenticated={isAuthenticated} />
            </span>
          </div>
        </div>
        <div className={classes.contactUs}>
          {cloaked ? (
            <>
              <div
                className={classes.slidingText}
                style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
                onClick={handleTextClick}
              >
                Contact us: <a className="contact-links" href="mailto:support@thesneakercave.com">support@thesneakercave.com</a> | <a className="contact-links" href="tel:+359877046728">+359 877046728 | </a> 
                We're here to help!
              </div>
              <div
                className={classes.slidingText}
                style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
                onClick={handleTextClick}
              >
                Contact us: <a className="contact-links" href="mailto:support@thesneakercave.com">support@thesneakercave.com</a> | <a className="contact-links" href="tel:+359877046728">+359 877046728 | </a> 
                We're here to help!
              </div>
            </>
          ) : 
          <>
            <div
            className={classes.slidingText}
            style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
            onClick={handleTextClick}
            >
              Contact us: <a className="contact-links" href="mailto:support@thesneakercave.com">support@thesneakercave.com</a> | <a className="contact-links" href="https://t.me/+4fxybghXqF1iOTQ0">@TheSneakerCave</a> | <a className="contact-links" href="https://wa.me/447453347455">+44 7453347455 | </a> 
              We're here to help!
            </div>
            <div
              className={classes.slidingText}
              style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
              onClick={handleTextClick}
            >
              Contact us: <a className="contact-links" href="mailto:support@thesneakercave.com">support@thesneakercave.com</a> | <a className="contact-links" href="https://t.me/+4fxybghXqF1iOTQ0">@TheSneakerCave</a> | <a className="contact-links" href="https://wa.me/447453347455">+44 7453347455 | </a> 
              We're here to help!
            </div>
          </>
        }
      
    </div>
      </div>
    </>
  );
}

export default Header;
