import React from "react";
import "./TermsAndCondtion.css";
import MetaData from "../component/layouts/MataData/MataData";
import { useSelector } from "react-redux";

const ExchangePolicy = () => {
  const { cloaked } = useSelector((state) => state.products);

  return (
    <div className="terms-container">
      <MetaData title="Exchange Policy" />
      <div className="terms-overlay">
        <h1 className="terms-title">Exchange Policy</h1>
      </div>
      <div className="terms-content">
        <p>
          The Sneaker Cave (“we” and “us”) is the operator of{" "}
          <a href="https://thesneakercave.com/">https://thesneakercave.com/</a>{" "}
          (“Website”). By placing an order through this Website you will be
          agreeing to the terms of use. These are provided to ensure both
          parties are aware of and agree upon this arrangement to mutually
          protect and set expectations on our service.
        </p>

        <h3>Cancellation & Exchange</h3>
        <p>The shipping costs for cancellation are covered by the customer.</p>

        <h3>Procedure</h3>
        <ul>
          <li>
            You need to notify us in advance that you want to make an exchange
            via email –{" "}
            <a href="mailto:support@thesneakercave.com">
              support@thesneakercave.com
            </a>
            .
          </li>
          <li>
            Please do not send a package to us before we have confirmed that we
            can make the exchange.
          </li>
          <li>
            The Sneaker Cave is not obligated to exchange products that cannot
            be exchanged due to lack of availability of the desired products!
          </li>
          <li>
            You need to send the package to the address agreed upon when
            contacting our team.
          </li>
          <li>
            Please pack the shipment in its original packaging to protect it
            from contamination or damage during delivery to us.
          </li>
        </ul>

        <h3>Requirements for Exchange</h3>
        <ul>
          <li>The product has not been worn or washed.</li>
          <li>The product is not damaged and has no defects.</li>
          <li>
            The product is returned in its original packaging, along with all
            tags, in the same condition as it was received.
          </li>
        </ul>
        <p>
          The dispatch of the new shipment occurs within 3 days after processing
          the returned shipment.
        </p>

        <h3>Important Information</h3>
        <ul>
          <li>
            The user has the right to return the purchased goods within 14
            working days from the date of receipt.
          </li>
          <li>
            The product must be in its original packaging, without any signs of
            use or violation of the commercial appearance of the product in
            accordance with the provisions of Art. 55(1) of the Consumer
            Protection Act.
          </li>
          <li>
            After verification within 14 days, the buyer, who bears all
            transport costs, will receive a refund.
          </li>
        </ul>

        <h3>Additional Requirements for Cancellation</h3>
        <ul>
          <li>
            The exchange or complaint must occur within 14 days from the date of
            receipt of the shipment.
          </li>
          <li>
            Products subject to return or exchange are those that have not
            undergone any processing (washing, ironing, cutting, and other
            actions that violate their integrity).
          </li>
        </ul>
        <p>
          All shipments are opened before the refund is made. Once we confirm
          that the shipment meets the specified conditions, the amount will be
          refunded to you within 3 working days via postal money order. To
          receive the money order at the courier's office, you will only need an
          ID card. If the described conditions are not met, the complaint
          request will not be considered.
        </p>
        <h2>Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <p style={{ fontWeight: "500" }}>TheSneakerCave</p>
        {
          cloaked 
            ?
            <p style={{ fontWeight: "500" }}>Zhitnitsa Village, Postal Code 9278, Provadya Municipality, Varna Province.</p>
            :
            <p style={{ fontWeight: "500" }}>8 Argall Ave, London E10 7QE, United Kingdom</p>
        }
        <p style={{ fontWeight: "500" }}>
          Email:{" "}
          <span style={{ fontWeight: "400" }}>support@thesneakercave.com</span>
        </p>
        { cloaked 
        ? 
        <p style={{ fontWeight: "500" }}>
          Phone Number:{" "}
          <span style={{ fontWeight: "400" }}>+359877046728</span>
        </p>
        :
        <p style={{ fontWeight: "500" }}>
          Phone Number:{" "}
          <span style={{ fontWeight: "400" }}>+447453347455</span>
        </p>
        }
      </div>
    </div>
  );
};

export default ExchangePolicy;
