import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { FitScreen } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { dispalyMoney, generateDiscountedPrice } from "../DisplayMoney/DisplayMoney";
import { addItemToCart } from "../../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    height: FitScreen,
    margin: theme.spacing(1.5),
    backgroundColor: "white",
    color: "#000",
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  media: {
    width: "100%",           /* Ensures image takes up full width of the container */
    height: "auto",          /* Adjust height based on width, maintaining aspect ratio */
    minHeight: "130px",      /* Minimum height to prevent the image from collapsing */
    maxHeight: "auto",       /* Allow height to adjust freely */
    display: "block",        /* Block-level element for proper layout behavior */
    maxWidth: "100%",        /* Prevent image from overflowing its container */
    objectFit: "contain",    /* Fit the image within its container, without cropping */
    margin: "0 auto",        /* Center the image horizontally in the container */
    padding: "5px",          /* Adds some padding around the image */
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", /* Optional subtle shadow for depth */
    backgroundColor: "#f9f9f9", /* Adds a subtle background color for visual separation */
    transition: "transform 0.3s ease", /* Smooth animation on hover */    
  },
  mediaCloaked: {
    width: "100%",          /* Ensure the image takes up the full width of the container */
    height: "auto",         /* Height will auto-adjust based on width */
    minHeight: "130px",     /* Minimum height of the image */
    maxHeight: "auto",      /* Let the height adjust dynamically */
    display: "block",       /* Block-level element to allow full-width scaling */
    objectFit: "cover",     /* Ensures the image covers the square area without distortion */
    aspectRatio: 1 / 1,     /* Ensures the image stays square (1:1 aspect ratio) */
  },
    button: {
    backgroundColor: "black",
    color: "white",
    borderRadius: 4,
    fontWeight: "bold",
    width: "100%",
    height: 22.5,  // Halved the original height
    "&:hover": {
      backgroundColor: "#00aaa4",
      color: "black",
      fontWeight: "bold",
    },
  },
  oldPrice: {
    textDecoration: "line-through",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.6)",
    marginRight: theme.spacing(0.5),  // Halved the original margin
  },
  finalPrice: {
    fontWeight: "bold",
    fontSize: "0.6rem",  // Halved the original font size
    color: "#00aaa4",
  },
  description: {
    fontSize: "0.7rem",  // Halved the original font size
    fontWeight: 500,
    marginTop: theme.spacing(0.5),  // Halved the original margin
    marginBottom: theme.spacing(0.5),  // Halved the original margin
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
}));

const HomeCard = ({ product }) => { 
  const dispatch = useDispatch();
  const classes = useStyles();
  const oldPrice = dispalyMoney(product.price);
  const { cloaked } = useSelector((state) => state.products);

  const truncated =
    "🔥MadeToBeWorn, not resold 🔥Affordable drip, using real materials🔥Factory-direct UA shoes!"
      .split(" ")
      .slice(0, 5)
      .join(" ") + "...";
    const nameTruncated = product.name.split(" ").slice(0, 5).join(" ") + "...";
      // const addTocartHandler = (id) => {
      //   dispatch(addItemToCart(id , true))
      // }
  const addTocartHandler = (id, qty) => {
    dispatch(addItemToCart(id, qty));
  };
  return (
    <Card className={classes.root}>
      <Link
        onClick={() => window.fbq('track', 'ViewContent', {
          content_ids: [product._id],
          content_type: 'product'
        })}
        className="productCard"
        to={`/product/${product._id}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <CardActionArea>
          <CardMedia className={cloaked ? classes.mediaCloaked : classes.media} image={product.images[0].url} />
          <CardContent>
            <Typography
              gutterBottom
              color="black"
              fontWeight="bold"
              style={{ fontWeight: "700", fontSize: '0.7rem', color: "#000" }}  // Adjusted font size
            >
              {nameTruncated}
            </Typography>
            <Box display="flex" alignItems="center">
              {/* <Rating
                name="rating"
                value={product.ratings}
                precision={0.1}
                readOnly
                size="small"
                style={{ color: "#00aaa4", marginRight: 4, fontWeight: "400" }}  // Adjusted margin and font weight
              />
              <Typography variant="body2" color="textSecondary">
                ({product.numOfReviews})
              </Typography> */}
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.description}
            >
              {truncated}
            </Typography>
            <Box display="flex" alignItems="center">
              {/* <Typography variant="body1" className={classes.oldPrice}>
                {oldPrice}
              </Typography> */}
              <Typography variant="body1" className={classes.finalPrice}>
                {oldPrice}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
      {/* <Box display="flex" justifyContent="center" p={2}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => addTocartHandler(product._id)}
          disabled={product.Stock <= 0}
        >
          {product.Stock <= 0 ? 'Изчерпан' : 'Добави в количка'}
        </Button>
      </Box> */}
    </Card>
  );
};

export default HomeCard;
